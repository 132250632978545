<template>
  <div>
    <div class="my-2">
      <!-- Table Top -->
      <b-col
        cols="12"
        class="pb-2"
      >
        <b-form-radio-group
          v-model="searchType"
          button-variant="outline-primary"
          :options="optionsRadio"
          buttons
          size="sm"
          name="radios-btn-default"
        />
      </b-col>
      <b-row align-h="between">
        <!-- v-if="hasSource === false" -->
        <!-- Search -->
        <b-col
          v-if="searchType === 'name'"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-start">
            <vue-autosuggest
              v-model="query"
              :suggestions="filteredOptions"
              :input-props="inputProps"
              :get-suggestion-value="getSuggestionValue"
              :limit="10"
              @selected="onSelected"
              @click="clickHandler"
              @input="onInputChange"
            >
              <template slot-scope="{ suggestion }">
                <span class="my-suggestion-item">{{
                  suggestion.item.itemArabicName
                }}</span>
              </template>
            </vue-autosuggest>
          </div>
        </b-col>
        <b-col
          v-if="searchType === 'barcode'"
          cols="12"
          md="5"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="filter.barcode"
              class="d-inline-block mr-1"
              :clearable="true"
              :placeholder="$t('search')"
              @keydown.enter.prevent="getItemWithBarcode"
              @dblclick="
                () => $refs.searchItemsModal.openModal(transactionType)
              "
            />
          </div>
        </b-col>
        <b-col
          md="2"
          class="d-flex justify-content-end"
        >
          <b-button
            variant="primary"
            @click="
              (v) => {
                $refs.searchItemsModal.openModal(transactionType);
              }
            "
          >
            {{ $t("addNewItem") }}
          </b-button>
        </b-col>
      </b-row>
    </div>

    <b-table
      ref="g-table"
      :items="items"
      :fields="columns"
      primary-key="barcode"
      show-empty
      hover
      small
      foot-clone
      sticky-header="100%"
      sticky-column
      :per-page="items.length"
      :current-page="currentPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="isSortDirDesc"
      :sort-direction="sortDirection"
      class="mb-0"
      :empty-text="$t('noMatchingRecordsFound')"
    >
      <template #table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="{ width: '180px' }"
        >
      </template>
      <template #head(actions)>
        <span />
      </template>
      <template #cell(serial)="{ item }">
        <g-field
          id="serial"
          class="mt-1"
          :value.sync="item.serial"
          rules="required"
          size="sm"
          name="serial"
        />
      </template>
      <template #cell(carColorId)="{ item }">
        <g-field
          style="position: relative !important"
          field="select"
          class="select-size-sm my-0 custom-dropdown"
          :value.sync="item.carColorId"
          :options="carColors"
          :tool-tip-error="true"
          :label="isRight ? 'arabicName' : 'englishName'"
          :dir="isRight ? 'rtl' : 'ltr'"
          rules="required"
          @input="
            (v) => {
              computeTotal({ item: item });
            }
          "
        />
      </template>
      <template #cell(carStatus)="{ item }">
        <g-field
          style="position: relative !important"
          field="select"
          class="select-size-sm my-0 custom-dropdown"
          :value.sync="item.carStatus"
          :options="carConditions"
          :tool-tip-error="true"
          :label="isRight ? 'arabicName' : 'englishName'"
          :dir="isRight ? 'rtl' : 'ltr'"
          rules="required"
          @input="
            (v) => {
              computeTotal({ item: item });
            }
          "
        />
      </template>
      <template #cell(arabicName)="{ item }">
        <span style="min-width: 150px"> {{ item.arabicName }}</span>
      </template>
      <template #cell(discount)="{ item }">
        <b-row>
          <b-col
            cols="6"
            class="p-0 pr-1"
          >
            <g-field
              :value.sync="item.discountValue"
              placeholder="value"
              type="number"
              size="sm"
              class="my-0"
              @input="
                (v) => {
                  computeTotal({
                    item: item,
                    discountValue: item.discountValue,
                  });
                }
              "
            />
          </b-col>
          <b-col
            cols="6"
            class="p-0"
          >
            <g-field
              :value.sync="item.discountPercentage"
              placeholder="%"
              type="number"
              size="sm"
              class="my-0"
              @input="
                (v) => {
                  computeTotal({
                    item: item,
                    discountPercentage: item.discountPercentage,
                  });
                }
              "
            >
              <template #append>
                <b-input-group-text> % </b-input-group-text>
              </template>
            </g-field>
          </b-col>
        </b-row>
      </template>

      <template #cell(quantity)="{ item }">
        <g-field
          :value.sync="item.quantity"
          :disabled="true"
          type="number"
          :rules="`required|min_value:0|${
            hasSource
              ? 'less_or_equal:' +
                (item.originalQuantity - (item.consumedQuantity || 0))
              : ''
          }`"
          :tool-tip-error="false"
          label-text="quantity"
          :hide-lable="true"
          size="sm"
          class="my-0"
          :style="{ width: '100px' }"
          @input="
            (v) => {
              computeTotal({ item: item });
            }
          "
        />
      </template>

      <template #cell(price)="{ item }">
        <g-field
          class="mb-0"
          :value.sync="item.price"
          type="number"
          rules="required"
          :short-desc="true"
          size="sm"
          :style="{ width: '100px' }"
          @input="
            (v) => {
              computeTotal({ item: item });
            }
          "
          @blur="zeroPrice(item)"
          @change="zeroPrice(item)"
        />
      </template>
      <template #cell(notes)="{ item }">
        <g-field
          :value.sync="item.notes"
          :short-desc="true"
          size="sm"
          class="my-0"
        />
      </template>
      <!-- <template #cell(attachments)="{ item }">
        <input
          type="file"
          style="width: 200px"
          accept=".pdf,.png,.jpg"
          multiple
          @change="handleFileUpload($event, item)"
        />
        <span v-for="(attach, index) in item.attachmentDetails" :key="index"> {{ attach.attachmentFileName }}, </span>
      </template> -->
      <template #cell(actions)="{ item }">
        <div class="text-nowrap">
          <b-button
            v-b-tooltip.hover.top="$t('delete')"
            variant="flat-danger"
            size="sm"
            @click="deleteItem(item)"
          >
            <feather-icon
              :id="`invoice-row-${item.id}-delete-icon`"
              icon="TrashIcon"
              stroke="red"
              class="mx-0 clickable danger"
            />
          </b-button>
          <b-button
            v-if="item.serial"
            v-b-tooltip.hover.top="$t('attachments')"
            variant="flat-primary"
            size="sm"
            @click="openModal(item)"
          >
            <feather-icon
              :id="`invoice-row-${item.id}-primary-icon`"
              icon="FileIcon"
              class="mx-0 clickable warning"
            />
          </b-button>
        </div>
      </template>
      <template #foot()>
        <span />
      </template>
      <template #foot(quantity)>
        <span> {{ fraction(totalQuantity) }} </span>
      </template>
      <template #foot(total)>
        <span> {{ fraction(totalPrices) }} </span>
      </template>
      <template #foot(discount)>
        <span> {{ fraction(totalDiscountValue) }} </span>
      </template>
      <template #foot(net)>
        <span> {{ fraction(totalNetPrice) }} </span>
      </template>
    </b-table>
    <b-modal
      ref="attachments-modal"
      no-close-on-backdrop
      cancel-variant="outline-secondary"
      centered
      size="xl"
      hide-footer
      :title="$t('attachments')"
    >
      <!-- <g-form @submit="send"> -->
      <b-row>
        <b-col
          v-for="(item, index) in selectedItemAttached"
          :key="index"
          md="2"
          class="text-center"
        >
          <span
            class="removeImage text-right btn-block"
            @click="deleteImage(item)"
          >&times;</span>
          <!-- media -->
          <b-media
            v-if="
              item.filePath === 'png' ||
                item.filePath === 'jpg' ||
                item.filePath === 'peg' ||
                item.filePath === 'PNG' ||
                item.filePath === 'JPG' ||
                item.filePath === 'PEG' ||
                item.attachmentFileExtension === 'png' ||
                item.attachmentFileExtension === 'jpg' ||
                item.attachmentFileExtension === 'peg' ||
                item.attachmentFileExtension === 'PNG' ||
                item.attachmentFileExtension === 'JPG' ||
                item.attachmentFileExtension === 'PEG'
            "
            no-body
            class="d-block m-auto text-center"
          >
            <!-- <b-media-aside> -->
            <b-link>
              <b-img
                v-if="item.attachmentUrl"
                ref="previewEl"
                rounded
                height="150"
                width="150"
                :src="item.attachmentUrl"
                @click="showPreview(item.attachmentUrl)"
              />
            </b-link>
          <!--/ avatar -->
            <!-- </b-media-aside> -->
          </b-media>
          <div v-else>
            <feather-icon
              icon="FileIcon"
              size="150"
            />
          </div>
          <b-row class="mt-75">
            <b-col
              md="12"
              class="text-center"
            >
              <b-button
                v-if="item.attachmentUrl.startsWith(baseUrl)"
                variant="primary"
                class="btn-sm btn btn-primary"
                @click.prevent="downloadItem(item)"
              >
                <!-- <span>{{ $t("attachedFile") }}</span> -->
                <feather-icon
                  icon="DownloadIcon"
                  size="16"
                  class="ml-25"
                />
              </b-button>
              <input
                type="file"
                class="excel-upload-input mt-1"
                accept=".pdf,.png,.jpg,.jpeg,.PDF,.PNG,.JPG,.JPEG"
                @change="handleFileReplace( $event, item )"
              >
            </b-col>
          </b-row>
        </b-col>
        <b-col
          v-if="selectedItemAttached.length < 5"
          md="2"
        >
          <input
            type="file"
            style="width: 200px"
            accept=".pdf,.png,.jpg,.jpeg,.PDF,.PNG,.JPG,.JPEG"
            multiple
            @change="handleFileUpload($event, clickedItem)"
          >
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      ref="upload-modal"
      no-close-on-backdrop
      cancel-variant="outline-secondary"
      centered
      size="xl"
      hide-footer
      :title="$t('attachments')"
    >
      <b-row>
        <b-col
          v-for="(item, index) in clickedItem.attachmentDetails"
          :key="index"
          md="2"
          class="text-center"
        >
          <span
            class="removeImage text-right btn-block"
            @click="deleteImage(item)"
          >&times;</span>
          <!-- media -->
          <b-media
            v-if="
              item.filePath === 'png' ||
                item.filePath === 'jpg' ||
                item.filePath === 'peg' ||
                item.filePath === 'PNG' ||
                item.filePath === 'JPG' ||
                item.filePath === 'PEG' ||
                item.attachmentFileExtension === 'png' ||
                item.attachmentFileExtension === 'jpg' ||
                item.attachmentFileExtension === 'peg' ||
                item.attachmentFileExtension === 'PNG' ||
                item.attachmentFileExtension === 'JPG' ||
                item.attachmentFileExtension === 'PEG'
            "
            no-body
            class="d-block m-auto text-center"
          >
            <!-- <b-media-aside> -->
            <b-link>
              <b-img
                v-if="item.attachmentUrl"
                ref="previewEl"
                rounded
                height="150"
                width="150"
                :src="item.attachmentUrl"
                @click="showPreview(item.attachmentUrl)"
              />
            </b-link>
          <!--/ avatar -->
            <!-- </b-media-aside> -->
          </b-media>
          <div v-else>
            <feather-icon
              icon="FileIcon"
              size="150"
            />
          </div>
        </b-col>
        <b-col
          v-if="clickedItem.attachmentDetails && clickedItem.attachmentDetails.length < 5"
          md="2"
        >
          <input
            type="file"
            style="width: 200px"
            accept=".pdf,.png,.jpg,.jpeg,.PDF,.PNG,.JPG,.JPEG"
            multiple
            @change="handleFileUpload($event, clickedItem)"
          >
        </b-col>
        <!-- <input
          type="file"
          style="width: 200px"
          accept=".pdf,.png,.jpg"
          multiple
          @change="handleFileUpload($event, clickedItem)"
        />
        <ul>
        <li v-for="(attach, index) in clickedItem.attachmentDetails" :key="index"> {{ attach.attachmentFileName }} </li> -->
      <!-- </ul> -->
      </b-row>
    </b-modal>
    <!-- Modal for image preview -->
    <div
      v-if="previewImage"
      class="modalImage pt-5"
    >
      <span
        class="closeImage"
        @click="hidePreview"
      >&times;</span>
      <img
        :src="previewImage"
        class="modalImage-content"
      >
    </div>
    <search-items
      ref="searchItemsModal"
      :store-id="storeId"
      search-type="purchase"
      @addNewItems="addItems"
    />
  </div>
</template>

<script>
import searchItems from '@/pages/Shared/SearchItems.vue';
import { carConditions } from '@/libs/acl/Lookups';
import saveAs from 'file-saver';

export default {
  components: {
    searchItems,
  },
  emits: ['update:items'],
  props: {
    items: {
      type: [Array, Function],
      default: () => [],
    },
    storeId: {
      type: [String, Number, null],
      required: false,
      default: null,
    },
    hasSource: {
      type: Boolean,
      default: false,
    },
    transactionType: {
      type: [String, Number, null],
      required: false,
      default: null,
    },
    totalExpenses: {
      type: [Number, null],
      required: false,
      default: 0,
    },
    invoiceTotal: {
      type: [Number, null],
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      perPage: 25,
      searchQuery: '',
      sortDirection: 'asc',
      isSortDirDesc: '',
      currentPage: 1,
      sortBy: '',
      filterOn: [],
      totalRows: 0,
      filter: {
        barcode: '',
      },
      error: {},
      carConditions,
      carColors: [],
      totalQuantity: 0.00,
      totalPrice: 0.00,
      totalPrices: 0.00,
      totalDiscountValue: 0.00,
      totalNetPrice: 0.00,
      barcodeVal: '',
      currentItems: [],
      inputProps: {
        id: 'autosuggest__input',
        class: 'form-control',
        placeholder: `${this.$t('search')}`,
      },
      datasuggest: [],
      filteredOptions: [],
      limit: 10,
      query: '',
      searchType: 'barcode',
      allowedTypes: ['application/pdf', 'image/png', 'image/jpeg'],
      selectedItemAttached: [],
      previewImage: null,
      clickedItem: {}
    };
  },
  computed: {
    isLineDiscount() {
      return this.currentBranch.discountLevel === 'items';
    },
    isEnablePurchaseCosts() {
      return this.currentBranch.enablePurchaseCosts === true;
    },
    optionsRadio() {
      if (this.currentBranch.activityType !== 'carShowroom') {
      return [
        { text: this.$t('barcode'), value: 'barcode' },
        { text: this.$t('name'), value: 'name' },
      ];
      }
      return [
        { text: this.$t('name'), value: 'name' },
      ]
    },
    columns() {
      return [
        {
          key: 'lineSerial',
          label: '',
          sortable: false,
          thStyle: { width: '10px' },
        },
        {
          key: 'serial',
          sortable: false,
          label: this.$t('serial'),
          thStyle: { width: '20%' },
        },
        {
          key: this.isRight ? 'itemArabicName' : 'itemEnglishName',
          label: this.$t('name'),
          sortable: false,
          thStyle: { width: '300px' },
          tdClass: ['nameTd', 'td-width'],
        },
        {
          key: 'carColorId',
          label: this.$t('color'),
          sortable: false,
          thStyle: { width: '250px' },
          tdClass: ['td-width'],
        },
        {
          key: 'carStatus',
          label: this.$t('condition'),
          sortable: false,
          thStyle: { width: '290px' },
          tdClass: ['td-width'],
        },
        {
          key: 'quantity',
          label: this.$t('Count'),
          sortable: false,
          thStyle: { width: '200px' },
        },
        {
          key: 'price',
          label: this.$t('purchPrice'),
          sortable: false,
          thStyle: { width: '200px' },
        },
        {
          key: 'costPrice',
          label: this.$t('costPrice'),
          sortable: false,
          thStyle: { width: '200px' },
          tdClass: ['td-width', this.isEnablePurchaseCosts ? '' : 'd-none'],
          thClass: this.isEnablePurchaseCosts ? '' : 'd-none',
          formatter: (value, key, item) => {
            if (this.totalExpenses > 0) {
              if (this.currentBranch.taxPolicy === 'itemBased') return this.fraction(((item.net / this.totalNetPrice) * this.totalExpenses) / item.quantity);
              return this.fraction(((item.total / this.invoiceTotal) * this.totalExpenses) / item.quantity);
            }
            return 0
          },
        },
        {
          key: 'total',
          label: this.$t('total'),
          sortable: false,
          thStyle: { width: '200px' },
        },
        {
          key: 'discount',
          label: this.$t('discount'),
          sortable: false,
          thStyle: { width: '400px' },
          tdClass: ['td-width', this.isLineDiscount ? '' : 'd-none'],
          thClass: this.isLineDiscount ? '' : 'd-none',
        },
        {
          key: 'net',
          label: this.$t('net'),
          sortable: false,
          thStyle: { width: '200px' },
          tdClass: this.isLineDiscount ? '' : 'd-none',
          thClass: this.isLineDiscount ? '' : 'd-none',
        },
        {
          key: 'notes',
          label: this.$t('notes'),
          sortable: false,
          thStyle: { width: this.currentBreakPoint === 'xl' ? '20%' : '30%' },
          tdClass: this.company.showNotesInTransactionLines ? '' : 'd-none',
          thClass: this.company.showNotesInTransactionLines ? '' : 'd-none',
        },
        // {
        //   key: 'attachments',
        //   label: this.$t('attachments'),
        //   sortable: false,
        //   thStyle: { width: '200px' },
        // },
        {
          key: 'actions',
          sortable: false,
          thStyle: { width: '200px' },
        },
      ];
    },
  },
  watch: {
    items() {
      this.getSummary();
      this.recalc();
    },
  },
  mounted() {
    this.searchType = this.currentBranch.activityType !== 'carShowroom' ? 'barcode' : 'name';
    this.getCarColors();
  },
  methods: {
    deleteImage(item) {
      const indx = this.clickedItem.attachmentDetails.indexOf(item);
      this.clickedItem.attachmentDetails.splice(indx, 1);
    },
    handleFileReplace(event, item) {
      this.file = event.target.files[0];
      const fileSize = this.file.size / 1024 / 1024;
      item.attachmentFileExtension = this.file.name.split('.').pop();
        this.toBase64(this.file).then((file1) => {
          item.attachmentBase64Content = file1.split(',').pop();
        });
        item.attachmentFileName = this.file.name;
        item.attachmentUrl = URL.createObjectURL(this.file);
        item.filePath = item.attachmentFileName.slice(-3);
      if (!this.isValidType(this.file)) {
        this.file = '';
        item.attachmentFileExtension = '';
        item.attachmentBase64Content = '';
        item.attachmentFileName = '';
        this.doneAlert({ text: this.$t('Only supports upload .pdf, .png, .jpg, .jpeg, .PDF, .PNG, .JPG, .JPEG suffix files'), type: 'error' });
        this.file = '';
        return false
      }
      if (this.isValidType(this.file) && fileSize > 5) {
        this.file = '';
        item.attachmentFileExtension = '';
        item.attachmentBase64Content = '';
        item.attachmentFileName = '';
        this.doneAlert({ text: this.$t('fileSizeExceedsFiveMiB'), type: 'error' });
        this.file = '';
        return false
      }
      const index = this.selectedItemAttached.findIndex((s) => s.attachmentLineSerial === item.attachmentLineSerial);
      this.$set(this.selectedItemAttached, index, item);
    },
    downloadItem(item) {
      const path = item.attachmentUrl.split('.')[1];
      if ((item.attachmentUrl && item.attachmentUrl.startsWith(this.baseUrl))) {
        saveAs(item.attachmentUrl, `attached-file.${path}`);
      } else {
        saveAs(`${this.baseUrl}${item.attachmentUrl}`, `attached-file.${path}`);
      }
    },
    showPreview(imageSrc) {
      this.previewImage = imageSrc;
    },
    hidePreview() {
      this.previewImage = null;
    },
    closeModal() {
      this.$refs['attachments-modal'].hide();
      this.selectedItemAttached = [];
    },
    openModal(item) {
        this.selectedItemAttached = []
        this.clickedItem = item || {}
        if (item.transactionId) {
        this.selectedItemAttached = item.attachmentDetails;
        this.selectedItemAttached.forEach(element => {
          element.filePath = element.attachmentUrl.slice(-3);
          if (element.filePath === 'pdf' || element.filePath === 'png' || element.filePath === 'jpg' || element.filePath === 'peg' || element.filePath === 'PNG' || element.filePath === 'JPG' || element.filePath === 'PEG') {
            if (element.attachmentUrl && !element.attachmentUrl.startsWith(this.baseUrl) && !element.attachmentBase64Content) {
              element.attachmentUrl = `${this.baseUrl}${element.attachmentUrl}`
            }
            if ((element.attachmentUrl && element.attachmentUrl.startsWith(this.baseUrl)) || element.attachmentBase64Content) {
              return element.attachmentUrl
            }
            if (element.attachmentUrl === null) {
            element.attachmentUrl = 'no_image.png';
            }
          }
        });
        this.$refs['attachments-modal'].show();
      } else {
        this.clickedItem = item || {}
        this.$refs['upload-modal'].show();
      }
      // });
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    async handleFileUpload(event, item) {
      this.clickedItem.attachmentDetails = []
      item.attachments = [];
      const fileList = event.target.files;
      const maxFileSizeMB = 5;

      for (let i = 0; i < fileList.length; i++) {
        const file = fileList[i];
        const fileSizeMB = file.size / 1024 / 1024;

        // Check if file type is allowed
        const fileExtension = file.name.split('.').pop();
        if (!this.isValidType(file)) {
          this.doneAlert({ text: this.$t('Only supports upload .pdf, .jpg, .jpeg, .png, suffix files'), type: 'error' });
          // Clear attachments array
          item.attachments = [];
          this.clickedItem.attachmentDetails = []
          // Clear input field value
          event.target.value = '';
          return false;
        }

        // Check if file size is within limits
        if (fileSizeMB > maxFileSizeMB) {
          this.doneAlert({ text: this.$t('fileSizeExceedsFiveMiB'), type: 'error' });
          // Clear attachments array
          item.attachments = [];
          this.clickedItem.attachmentDetails = []
          // Clear input field value
          event.target.value = '';
          return false;
        }
        if (fileList.length > 5) {
          this.doneAlert({
            text: this.$t('Maximum 5 files allowed.'),
            type: 'error'
          });
          // Clear attachments array
          item.attachments = [];
          this.clickedItem.attachmentDetails = []
          // Clear input field value
          event.target.value = '';
          return false;
        }
        const attachment = {
          attachmentFileExtension: fileExtension,
          attachmentBase64Content: null,
          attachmentFileName: file.name,
          itemSerial: item.serial,
          attachmentLineSerial: i + 1,
          lineSerial: item.lineSerial,
          transactionId: item.transactionId || 0,
          attachmentUrl: URL.createObjectURL(file)
        };

        // Convert file to base64 and update attachment's base64Content
        attachment.attachmentBase64Content = this.toBase64(file).then(base64 => base64.split(',').pop());

        // Push attachment to item's attachments array
        item.attachmentDetails.push(attachment);
        // this.clickedItem.attachmentDetails.push(attachment);
      }
      this.$refs['attachments-modal'].hide();
      return this.clickedItem.attachmentDetails
    },
    isValidType(file) {
      return /\.(pdf|jpg|jpeg|png|PDF|JPG|JPEG|PNG)$/.test(file.name)
    },
    clickHandler() {
    },
    onSelected(option) {
      // this.query = '';
      this.addItems([option.item]);
      this.query = '';
      this.filteredOptions = [];
    },
    getSuggestionValue(suggestion) {
      return suggestion.item.itemArabicName;
    },
    onInputChange(text) {
      if (text === '' || text === undefined) {
        this.filteredOptions = [];
        return
      }
      this.get({ url: `items?arabicNamePart=${text}&WholeWord=false&storeId=${this.storeId}&pageSize=25` }).then(({ data }) => {
        var itemArr = [];
        data.forEach((item) => {
          if (!item || !item.id) return;
          const store = item.stores.find((val) => val.storeId === this.storeId) || { currentBalance: 0, costprice: 0 };
          item.currentBalance = store.currentBalance;
          item.costPrice = store.costPrice;
          item.units = item.subUnits.map((unit) => {
            return {
              ...unit,
              itemId: item.id,
              costPrice: store.costPrice * unit.conversionRate,
            };
          });
          const units = item.subUnits;
          units.push({
            unitId: item.mainUnitId,
            unitArabicName: item.mainUnitArabicName,
            unitEnglishName: item.mainUnitEnglishName,
            salesPrice: item.salesPrice,
            minSalesPrice: item.minSalesPrice,
            conversionRate: 1,
            maxSalesPrice: item.maxSalesPrice,
            barcode: item.mainUnitBarcode,
            isMain: true,
          });
          itemArr.push({
            itemArabicName: item.arabicName,
            itemEnglishName: item.englishName,
            unitId: item.matchedUnitId || item.mainUnitId,
            code: item.code,
            itemCode: item.code,
            storeId: this.storeId,
            itemId: item.id,
            quantity: 1,
            costPrice: store.costPrice,
            convertedBalance: item.currentBalance / (units.find((u) => u.unitId === item.mainUnitId).conversionRate || 1),
            price: item.matchedUnitId ? units.find((u) => u.unitId === item.matchedUnitId).salesPrice : item.salesPrice,
            mainPrice: item.matchedUnitId ? units.find((u) => u.unitId === item.matchedUnitId).salesPrice : item.salesPrice,
            discountPercentage: 0,
            discountValue: 0,
            total: item.price * item.quantity,
            serial: '',
          serials: [],
          carStatus: null,
          carColorId: null,
          carColorName: '',
          carStatusArabicName: '',
          attachmentDetails: [],
            net: item.total,
            stores: item.stores,
            isTaxable: item.isTaxable,
            barCode: item.matchedUnitId ? units.find((u) => u.unitId === item.matchedUnitId).barcode : item.mainUnitBarcode,
            matchedUnitId: item.matchedUnitId,
            units,
          })
        })
        if (data && data.length > 0) {
          return itemArr;
        }
      }).then((data) => {
        if (data && data.length > 0) {
          const filteredItemsData = data.filter(item => item.itemArabicName.toLowerCase().indexOf(text.toLowerCase()) > -1).slice(0, this.limit)
          const filteredData = filteredItemsData;
          this.filteredOptions = [{
            data: filteredData,
          }]
        } else {
          this.filteredOptions = [{
            data: [],
          }]
        }
      });
    },
    addExtrnalItem() {
      var myWindow = window.open(`/#/items/new?barCode=${this.barcodeVal}`, '', `width=${window.screen.availWidth}height=${window.screen.availHeight}`);
      var popupTick = setInterval(function () {
        if (myWindow.closed) {
          clearInterval(popupTick);
          // this.refreshTable()
        }
      }, 500);
    },
    deleteItem(item) {
      const filteredItems = this.items.reduce((items, val) => {
        if (val !== item) {
          const index = items.length > 0 ? items[items.length - 1].lineSerial : 0;
          val.lineSerial = index + 1;
          items.push(val);
        }
        return items;
      }, []);
      this.$emit('update:items', filteredItems);
    },
    getItemWithBarcode() {
      if (!this.storeId || !this.filter.barcode) return;
      const code = this.filter.barcode;
      this.get({ url: `items?barcode=${this.filter.barcode}&WholeWord=true&storeId=${this.storeId}&pageSize=25` }).then(({ data }) => {
        var itemArr = [];
        if (!data.length) {
          this.barcodeVal = this.filter.barcode;
          this.confirmActionAdd(
            {
              text: this.$t('wantToAdd'),
            },
            () => {
              this.addExtrnalItem();
            }
          );
        }
        data.forEach((item) => {
          if (!item || !item.id) return;
          const store = item.stores.find((val) => val.storeId === this.storeId) || { currentBalance: 0, costprice: 0 };
          item.currentBalance = store.currentBalance;
          item.costPrice = store.costPrice;
          const units = item.subUnits.map((unit) => {
            return {
              ...unit,
              itemId: item.id,
              costPrice: store.costPrice * unit.conversionRate,
            };
          });
          units.push({
            unitId: item.mainUnitId,
            unitArabicName: item.mainUnitArabicName,
            unitEnglishName: item.mainUnitEnglishName,
            costPrice: item.costPrice,
            salesPrice: item.salesPrice,
            minSalesPrice: item.minSalesPrice,
            maxSalesPrice: item.maxSalesPrice,
            barcode: item.mainUnitBarcode,
            isMain: true,
          });

          itemArr.push({
            itemArabicName: item.arabicName,
            itemEnglishName: item.englishName,
            unitId: item.matchedUnitId || item.mainUnitId,
            code: item.code,
            itemCode: item.code,
            storeId: this.storeId,
            itemId: item.id,
            quantity: 1,
            price: item.matchedUnitId ? units.find((u) => u.unitId === item.matchedUnitId).costPrice : item.costPrice,
            discountPercentage: 0,
            discountValue: 0,
            total: 0,
            net: 0,
            stores: item.stores,
            isTaxable: item.isTaxable,
            barCode: item.matchedUnitId ? units.find((u) => u.unitId === item.matchedUnitId).barcode : item.mainUnitBarcode,
            matchedUnitId: item.matchedUnitId,
            units,
          });
        })
        if (data && data.length > 0) {
          itemArr = itemArr.filter(function (_item) {
            return _item.barCode === code
          });
          this.addItems(itemArr);
        }
        this.filter.barcode = '';
      });
    },
    getSummary() {
      this.totalQuantity = 0;
      this.totalPrices = 0;
      this.totalNetPrice = 0;
      this.totalDiscountValue = 0;

      this.items.forEach((item) => {
        this.totalQuantity += parseFloat(item.quantity) || 0;
        this.totalPrices += parseFloat(item.total) || 0;
        this.totalNetPrice += parseFloat(item.net) || 0;
        this.totalDiscountValue += parseFloat(item.discountValue) || 0;
      });
    },
    recalc() {
      this.items.forEach((item) => {
        this.computeTotal({ item: item });
      });
    },
    computeTotal({ item, discountValue, discountPercentage }) {
      item.total = this.fraction(item.price * item.quantity);
      item.net = item.total;
      if (discountPercentage >= 0) {
        item.discountValue = (item.total * (discountPercentage / 100)).toFixed(2);
        item.net -= (item.total * discountPercentage) / 100;
      } else if (discountValue >= 0) {
        item.discountPercentage = ((discountValue / item.total) * 100).toFixed(2);
        item.net -= (discountValue || 0);
      } else {
        item.net -= item.discountValue || 0;
        if (item.total === 0) {
          item.discountPercentage = (0).toFixed(2);
        } else {
          item.discountPercentage = ((item.discountValue / item.total) * 100).toFixed(2);
        }
      }
      this.getSummary();
    },
    addItems(newItems) {
      newItems.forEach((item) => {
        const existItem = this.items.find((val) => val.itemId === item.itemId && val.unitId === item.unitId);
        const itemUnit = item.units.find((unit) => unit.unitId === item.unitId);
        item.price = itemUnit.costPrice || 0;
        if (!this.currentBranch.isItemTransactionLineDuplicateInPurchase) {
          if (!existItem) {
            item.total = item.price * item.quantity;
            item.net = item.total;
            this.items.push({
              ...item,
              lineSerial: this.items.length + 1,
            });
          } else {
            existItem.quantity = item.quantity + Number(existItem.quantity);
            this.computeTotal({ item: existItem });
          }
        } else if (this.currentBranch.isItemTransactionLineDuplicateInPurchase) {
          item.total = this.fraction(item.price) * this.fraction(item.quantity);
          item.net = this.fraction(item.total);
          this.items.push({
            ...item,
            lineSerial: this.items.length + 1,
          });
        }
        return true;
      });
      this.getSummary();
    },
    refreshTable() {
      this.$refs['g-table'].refresh();
    },
    zeroPrice(item) {
      if (item.price <= 0 && !this.currentBranch.allowZeroItemPrice) {
        if (Number.isNaN(item.discountPercentage)) {
          item.discountPercentage = 0;
        }
        this.doneAlert({
          text: this.$t('youAreNotAllowedToAddSalesPriceEqualZero'),
          type: 'error'
        });
      }
      if (this.currentBranch.allowZeroItemPrice) {
        item.discountPercentage = 0;
      }
    },
    getCarColors() {
      this.get({ url: 'CarColors' }).then((data) => {
        this.carColors = data;
      });
    },
  },
};
</script>

<style lang="scss">
.v-select {
  min-width: 100px !important;
}
.form-control {
  min-width: 50px !important;
}
.scroll-area {
  position: static;
  width: 100%;
  height: 80%;
}
.nameTd {
  white-space: nowrap !important;
}
#autosuggest {
  width: 75% !important;
}
.autosuggest__results-container .autosuggest__results {
  background-color: #fff;
  margin-top: 1rem;
  border-radius: 0.5rem;
  -webkit-box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),
    0 5px 15px 0 rgba(0, 0, 0, 0.08) !important;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),
    0 5px 15px 0 rgba(0, 0, 0, 0.08) !important;
  position: absolute;
  width: 71%;
  overflow-y: auto;
  max-height: 40vh;
  z-index: 999;
}
.autosuggest__results-container .autosuggest__results ul li:hover {
  background: #ededed;
  cursor: pointer;
}
.autosuggest__results-container .autosuggest__results ul li {
  list-style: none;
  padding: 0.75rem 1rem;
  cursor: pointer;
}
.autosuggest__results-container .autosuggest__results ul {
  padding-left: 0;
  margin-bottom: 0;
}
// .form-control {
//   min-width: 80px !important;
// }
// .td-width {
//   min-width: 200px;
// }
.nameTd {
  white-space: nowrap !important;
}
.custom-dropdown .vs__dropdown-menu {
  position: relative !important;
}

.removeImage {
  font-size: 23px;
    padding: 0 !important;
    margin: 0 !important;
    cursor: pointer;
    color: red;
  position: initial;
  top: 10px;
  right: 25px;
  font-size: 25px;
  font-weight: bold;
  transition: 0.3s;
}
.removeImage:hover,
.removeImage:focus {
  color: rgb(186, 2, 2);
  text-decoration: none;
  cursor: pointer;
}
</style>
