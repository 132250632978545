<template>
  <div>
    <b-row
      v-if="this.id"
      class="text-center bg-white m-25 p-25"
    >
      <b-col md="9">
        <div>
          <b-pagination
            v-model="currentPageList"
            class="justify-content-center mb-0"
            :total-rows="cachedRows"
            :per-page="cachedPerPage"
            :first-text="$t('first')"
            :prev-text="$t('prev')"
            :next-text="$t('next')"
            :last-text="$t('last')"
            @change="(v) => {
              getItemChange(v)
            }"
          />
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        md="9"
        lg="9"
      >
        <b-card>
          <b-row class="p-0 mb-1">
            <!-- <b-col cols="4">
              <b-button
                v-if="transactionType === 'purch'"
                :variant="file.name ? 'success' : 'relief-primary'"
                data-action-type="new"
                class="mr-1 btn-sm"
                @click="
                  (v) => {
                    openModal();
                  }
                "
              >
                {{ file.name ? $t('hasAttachMents') : $t('attachFile') }}
                <feather-icon
                  icon="UploadIcon"
                  size="15"
                  class="ml-25"
                />
              </b-button>
              <a
                v-if="transactionType === 'purch' && id && selectedItem.attachmentUrl"
                :href="selectedItem.attachmentUrl"
                class="mr-1 btn-sm btn btn-success"
                @click.prevent="downloadItem(selectedItem)"
              >
                {{ $t('attachedFile') }}
                <feather-icon
                  icon="DownloadIcon"
                  size="15"
                  class="ml-25"
                />
              </a>
            </b-col> -->
            <b-col
              v-if="!selectedItem.postedVoucherId"
              cols="8"
            />
            <b-col
              v-if="selectedItem.postedVoucherId"
              cols="4"
              class="text-center"
            >
              <b-button
                v-if="transactionType === 'purch'"
                :variant="'dark'"
                data-action-type="new"
                class="mr-1 btn-sm"
                @click="
                  (v) => {
                    this.$router.push({
                      name: 'vouchers-edit',
                      params: { id: selectedItem.postedVoucherId },
                    });
                  }
                "
              >
                {{ `${$t('voucherNum')} ${selectedItem.voucherCode}` }}
              </b-button>
            </b-col>
            <b-col
              cols="4"
              class="d-flex justify-content-end"
            >
              <!-- <b-button
                v-if="currentBranch.enablePurchaseCosts"
                v-b-tooltip.hover="$t('enablePurchaseCosts')"
                variant="relief-primary"
                style="margin-left:6px;"
                size="sm"
                @click="() => {
                  openExpensesModal();
                }"
              > {{ $t('enablePurchaseCosts') }}
              </b-button>
              <b-button
                v-if="accountObject.isDistributable"
                v-b-tooltip.hover="$t('enableAccountCostCenters')"
                variant="relief-primary"
                style="margin-left:6px;"
                size="sm"
                @click="() => {
                  openCostCentersModel();
                }"
              > {{ $t('enableAccountCostCenters') }}
              </b-button> -->
              <b-button
                v-b-tooltip.hover="$t('addNewItemForList')"
                variant="relief-primary"
                size="sm"
              >
                <feather-icon
                  icon="ExternalLinkIcon"
                  class="clickable"
                  @click="() => {
                    addExtrnalItem()
                  }"
                />
              </b-button>
            </b-col>
          </b-row>
          <hr class="border-bottom-primary border-darken-2">
          <g-form @submit="save">
            <b-row class="my-0">
              <b-col
                v-if="id > 0"
                md="4"
              >
                <g-field
                  :value.sync="selectedItem.code"
                  label-text="code"
                  disabled
                />
              </b-col>
              <b-col
                v-if="currentBranch.enableEditingTransactionDates"
                md="4"
              >
                <g-picker
                  :value.sync="selectedItem.transactionDate"
                  label-text="date"
                  name="date"
                />
              </b-col>
              <b-col
                v-if="!currentBranch.enableEditingTransactionDates"
                md="4"
              >
                <g-field
                  :value="getDate(selectedItem.transactionDate)"
                  label-text="date"
                  disabled
                  name="date"
                />
              </b-col>
              <b-col md="4">
                <g-field
                  :value.sync="selectedItem.transactionTime"
                  label-text="transactionTime"
                  name="transactionTime"
                  readonly
                />
              </b-col>
              <!-- <b-col md="4">
                <g-field
                  label-text="paymentType"
                  field="select"
                  rules="required"
                  :dir="isRight ? 'rtl' : 'ltr'"
                  :options="itemTransactionTypes"
                  :label="isRight ? 'arabicName' : 'englishName'"
                  :value.sync="selectedItem.paymentType"
                  disabled
                />
              </b-col> -->
              <b-col md="4">
                <g-field
                  :options="lookups.stores"
                  label-text="store"
                  field="select"
                  rules="required"
                  name="StoreId"
                  :value.sync="selectedItem.storeId"
                  :dir="isRight ? 'rtl' : 'ltr'"
                  :label="isRight ? 'arabicName' : 'englishName'"
                  :disabled="isClosed || hasSource || (selectedItem.lines && selectedItem.lines.length > 0)"
                />
              </b-col>
              <b-col md="4">
                <label
                  style="font-size: 14px;margin-bottom: 7px;"
                  for="supplier"
                >
                  {{ $t('supplier') }}
                  <feather-icon
                    v-b-tooltip.hover="$t('addNewSupplier')"
                    icon="ExternalLinkIcon"
                    class="clickable"
                    @click="() => {
                      addExtrnalSupplier()
                    }"
                  />
                </label>
                <g-field
                  field="select"
                  :label="isRight ? 'arabicName' : 'englishName'"
                  :disabled="hasSource || disableEdit === true"
                  :value.sync="selectedItem.dealerId"
                  :dir="isRight ? 'rtl' : 'ltr'"
                  :options="lookups.suppliers"
                  @change="(v) => changeCustomer(v)"
                />
              </b-col>
              <!-- <b-col md="4">
                <g-field
                  label-text="agent"
                  :value.sync="selectedItem.agentId"
                  :dir="isRight ? 'rtl' : 'ltr'"
                  :options="lookups.agents"
                  field="select"
                  :label="isRight ? 'arabicName' : 'englishName'"
                  :disabled="isClosed || hasSource || disableEdit === true"
                />
              </b-col> -->
              <b-col md="4">
                <g-field
                  id="ReferenceNumber"
                  v-silent-search
                  :value.sync="selectedItem.referenceNumber"
                  label-text="reference"
                  name="ReferenceNumber"
                  @keydown.enter="(v) => {
                    checkForReferenceNumber(selectedItem.referenceNumber);
                  }"
                />
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col
                v-if="currentBranch.activityType !== 'carShowroom'"
                cols="12"
              >
                <items
                  :store-id="selectedItem.storeId"
                  :items.sync="selectedItem.lines"
                  :total-expenses="totalExpenses"
                  :invoice-total="this.total"
                  :has-source="hasSource"
                  :transaction-type="transactionType"
                  @validate-balance="() => validate(invoiceValidationTypes.balance)"
                  @validate-salesPrice="() => validate(invoiceValidationTypes.salesPrice)"
                />
              </b-col>
              <b-col
                v-if="currentBranch.activityType === 'carShowroom'"
                cols="12"
              >
                <itemsForCars
                  :store-id="selectedItem.storeId"
                  :items.sync="selectedItem.lines"
                  :total-expenses="totalExpenses"
                  :invoice-total="this.total"
                  :has-source="hasSource"
                  :transaction-type="transactionType"
                  @validate-balance="() => validate(invoiceValidationTypes.balance)"
                  @validate-salesPrice="() => validate(invoiceValidationTypes.salesPrice)"
                />
              </b-col>
              <p
                v-if="isSerialRepeated"
                class="alert-danger p-75 w-100 text-center"
              >
                {{ $t('notAllowRepeatedSameSerial') }}
              </p>
              <b-col cols="12">
                <hr>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                v-show="isCash"
                md="8"
              >
                <b-col
                  cols="12"
                  class="pb-2"
                >
                  <h6>
                    <strong> {{ $t('paymentWay') }} </strong>
                  </h6>
                  <b-form-radio-group
                    v-model="payemntType"
                    button-variant="outline-primary"
                    :options="optionsRadio"
                    buttons
                    size="sm"
                    name="radios-btn-default"
                  />
                </b-col>
                <b-row>
                  <b-col
                    v-if="payemntType === 'Safes'"
                    md="5"
                    class="pr-1"
                  >
                    <g-field
                      :value="isRight ? safeTransactions.safeArabicName : safeTransactions.safeEnglishName"
                      label-text="safe"
                      disabled
                    />
                  </b-col>
                  <b-col
                    v-show="payemntType !== 'Safes'"
                    md="3"
                    class="pr-1"
                  >
                    <g-field
                      :value.sync="paymentMethod.otherPaymentMethodId"
                      :dir="isRight ? 'rtl' : 'ltr'"
                      :label="isRight ? 'arabicName' : 'englishName'"
                      :options="lookups.paymentMethods"
                      label-text="paymentWay"
                      field="select"
                      @change="
                        (val) => {
                          paymentMethod.otherPaymentMethodArabicName = val.arabicName;
                          paymentMethod.otherPaymentMethodEnglishName = val.englishName;
                          bankName = val.bankId
                        }
                      "
                    />
                  </b-col>
                  <b-col
                    v-show="payemntType !== 'Safes'"
                    md="3"
                    class="pr-1"
                  >
                    <g-field
                      :value.sync="bankName"
                      :dir="isRight ? 'rtl' : 'ltr'"
                      :label="isRight ? 'arabicName' : 'englishName'"
                      :options="banks"
                      label-text="bankName"
                      field="select"
                      disabled
                    />
                  </b-col>
                  <b-col
                    v-show="payemntType !== 'Safes'"
                    md="4"
                  >
                    <g-field
                      :value.sync="paymentMethod.value"
                      type="number"
                      :rules="isCash ? `min_value:0|less_or_equal:${remaindered || 0}` : ''"
                      :disabled="remaindered == 0"
                      label-text="value"
                      name="value"
                    />
                  </b-col>
                  <b-col
                    v-show="payemntType === 'Safes'"
                    md="4"
                  >
                    <g-field
                      :value.sync="paymentMethod.cashPaid"
                      type="number"
                      label-text="value"
                      name="value"
                    />
                  </b-col>
                  <b-col
                    md="2"
                    class="d-flex align-items-center"
                  >
                    <b-button
                      v-show="payemntType !== 'Safes'"
                      variant="gradient-primary"
                      class="btn-icon"
                      :disabled="!canAddNewPayment || (payemntType === 'Safes' && !safeTransactions.safeId)"
                      @click="addNewPaymentMethod"
                    >
                      <feather-icon icon="PlusIcon" />
                    </b-button>
                    <b-button
                      v-show="payemntType === 'Safes'"
                      variant="gradient-primary"
                      class="btn-icon"
                      :disabled="payemntType === 'Safes' && !safeTransactions.safeId"
                      @click="addNewPaymentMethod"
                    >
                      <feather-icon icon="PlusIcon" />
                    </b-button>
                  </b-col>
                </b-row>
                <b-col cols="12">
                  <g-table
                    ref="items-table"
                    foot-clone
                    per-page="50"
                    small
                    :items="selectedItem.payments"
                    :columns="tableColumns"
                    :hide-options="true"
                    :no-action="true"
                    :edit-button="{
                      visiable: false,
                      icon: 'PlusSquareIcon',
                      text: 'delete',
                      handler: (item) => {
                        $emit('addNewItem', item);
                      },
                    }"
                    :delete-button="{
                      visiable: true,
                      handler: (item) => {
                        selectedItem.payments = selectedItem.payments.filter((val) => val !== item);
                      },
                    }"
                    @on-create="
                      (v) => {
                        $refs['search-modal'].show();
                      }
                    "
                  />
                </b-col>
              </b-col>
              <b-col
                md="12"
                class="mt-auto"
              >
                <b-form-group :label="$t('notes')">
                  <b-form-textarea
                    id="textarea-state"
                    v-model="selectedItem.notes"
                    maxlength="1000"
                    :state="selectedItem.notes?selectedItem.notes.length <= 1000:''"
                    label-text="Notes"
                    rows="6"
                    max-rows="6"
                  />
                  <small class="textarea-counter-value float-right"><span class="char-count">{{ selectedItem.notes?selectedItem.notes.length :0 }}</span> / 1000 </small>
                </b-form-group>
              </b-col>
            </b-row>
            <template #footer>
              <small class="text-muted">Last updated 3 mins ago</small>
            </template>
          </g-form>
          <b-modal
            ref="invoices-modal"
            no-close-on-backdrop
            cancel-variant="outline-secondary"
            centered
            size="lg"
            hide-footer
            :title="$t('allInvoice')"
          >
            <div>
              <b-col cols="12">
                <g-table
                  ref="invoice-table"
                  :items="pendingItems"
                  :columns="tableColumnsInvoice"
                  :hide-options="true"
                  :no-action="true"
                  per-page="50"
                >
                  <template #actions="{ item }">
                    <div class="text-nowrap">
                      <feather-icon
                        :id="`invoice-row-${item.id}-UploadIcon`"
                        icon="UploadIcon"
                        stroke="blue"
                        class="mx-1 clickable danger"
                        @click="retriveInvoice(item)"
                      />
                      <b-tooltip
                        :title="$t('retrive')"
                        placement="bottom"
                        :target="`invoice-row-${item.id}-UploadIcon`"
                      />
                    </div>
                  </template>
                </g-table>
              </b-col>
            </div>
          </b-modal>
        </b-card>
      </b-col>
      <!-- totalsCards -->
      <b-col
        md="3"
        lg="3"
        class="d-flex flex-column"
      >
        <b-card class="border sticky-content-top">
          <b-row class="m-auto">
            <b-col
              md="3"
              class="p-0 d-flex align-items-center"
            >
              <span
                class="font-weight-bolder text-nowrap"
              >
                {{ $t('total') }}
              </span>
            </b-col>
            <b-col
              cols="12"
              md="9"
            >
              <g-field
                :value="fraction(selectedItem.total)"
                placeholder="total"
                type="number"
                name="total"
                size="sm"
                disabled
              />
            </b-col>
            <!-- discount -->
            <b-col
              md="3"
              class="p-0 d-flex align-items-center"
            >
              <span
                class="font-weight-bolder text-nowrap"
              >
                {{ $t('discont') }}
              </span>
            </b-col>
            <b-col
              md="4"
              lg="4"
              class="pr-0"
            >
              <g-field
                :value.sync="selectedItem.discountValue"
                :disabled="!total || isLineBaseDiscount"
                size="sm"
                type="number"
                :rules="`min_value:0|max_value:${selectedItem.total}`"
                placeholder="discountValue"
                name="discountValue"
                @input="(v) =>{
                  selectedItem.discountValue < 0 || selectedItem.discountValue > selectedItem.total ? v= 0: v;
                  selectedItem.discountValue = v;
                  selectedItem.discountPercentage = 0;
                  computeDiscount({ value: v })}"
              />
            </b-col>
            <b-col
              md="5"
              lg="5"
            >
              <g-field
                :value.sync="selectedItem.discountPercentage"
                :disabled="!total || isLineBaseDiscount"
                size="sm"
                placeholder="percentage"
                name="discountPercentage"
                type="number"
                rules="min_value:0|max_value:100"
                @input="(v) => {
                  selectedItem.discountPercentage < 0 || selectedItem.discountPercentage > 100 ? v= 0: v;
                  selectedItem.discountPercentage = v;
                  computeDiscount({ Percentage :v })
                }"
              >
                <template #append>
                  <b-input-group-text> % </b-input-group-text>
                </template>
              </g-field>
            </b-col>
            <b-col
              md="3"
              class="p-0 d-flex align-items-center"
            >
              <span
                class="font-weight-bolder"
              >
                {{ $t('netBeforeTax') }}
              </span>
            </b-col>
            <b-col
              cols="12"
              md="9"
            >
              <g-field
                :value="fraction(selectedItem.netBeforeTaxes)"
                size="sm"
                placeholder="total"
                name="total"
                type="number"
                disabled
                readonly
              />
            </b-col>
            <b-col
              md="3"
              class="p-0 d-flex align-items-center"
            >
              <span
                class="font-weight-bolder"
              >
                {{ $t('tax') }}
              </span>
            </b-col>
            <b-col
              cols="12"
              md="9"
            >
              <g-field
                :value="fraction(selectedItem.totalTaxes)"
                size="sm"
                placeholder="total"
                name="total"
                type="number"
                disabled
                readonly
              />
            </b-col>
            <b-col
              md="3"
              class="p-0 d-flex align-items-center"
            >
              <span
                class="font-weight-bolder"
              >
                {{ $t('net') }}
              </span>
            </b-col>
            <b-col
              cols="12"
              md="9"
            >
              <g-field
                :value="fraction(selectedItem.net)"
                size="sm"
                placeholder="net"
                type="number"
                name="Net"
                readonly
              />
            </b-col>
          </b-row>

          <hr>
          <b-row
            v-if="id"
            class="m-auto"
          >
            <b-col
              md="3"
              class="p-0 d-flex mb-1 align-items-center"
            >
              <span
                class="font-weight-bolder"
              >
                {{ $t('createdBy') }}
              </span>
            </b-col>
            <b-col
              cols="12"
              md="9"
            >
              <g-field
                :value="selectedItem.createdUserName"
                size="sm"
                placeholder="createdBy"
                name="createdBy"
                readonly
              />
            </b-col>
            <b-col
              v-if="selectedItem.updatedUserName"
              md="3"
              class="p-0 d-flex mb-1 align-items-center"
            >
              <span
                class="font-weight-bolder"
              >
                {{ $t('updatedBy') }}
              </span>
            </b-col>
            <b-col
              v-if="selectedItem.updatedUserName"
              cols="12"
              md="9"
            >
              <g-field
                :value="selectedItem.updatedUserName"
                size="sm"
                placeholder="updatedBy"
                name="updatedBy"
                readonly
              />
            </b-col>
          </b-row>
        </b-card>
        <b-card class="border sticky-content-top2">
          <b-row>
            <b-button
              v-if="id || currentBranch.isMaster"
              class="mx-1 btn-block"
              variant="outline-primary"
              :disabled="selectedItem.lines.length <= 0"
              @click="print(id)"
            >
              <feather-icon
                icon="PrinterIcon"
                class="mr-50"
              />
              {{ $t('print') }}
            </b-button>
            <b-button
              v-permission="$route.meta.permission"
              class="mx-1 btn-block"
              variant="outline-primary"
              data-action-type="saveAndPrint"
              :disabled="selectedItem.lines.length <= 0 || isConsumed || (!selectedItem.sourceTransactionId && selectedItem.isPosted) || selectedItem.isComplete || (this.currentBranch.activityType === 'carShowroom' && this.isSerialRepeated)"
              @click="checkDistribute('saveAndPrint')"
            >
              <feather-icon
                icon="PrinterIcon"
                class="mr-50"
              />
              {{ $t('saveAndPrint') }}
            </b-button>
            <b-button
              v-permission="$route.meta.permission"
              :disabled="selectedItem.lines.length <= 0 || isConsumed || (!selectedItem.sourceTransactionId && selectedItem.isPosted) || selectedItem.isComplete || (this.currentBranch.activityType === 'carShowroom' && this.isSerialRepeated) || helper.preventSave"
              data-action-type="save"
              class="mx-1 btn-block"
              variant="relief-primary"
              @click="checkDistribute"
            >
              <feather-icon
                icon="SaveIcon"
                class="mr-50"
              />
              {{ $t('save') }}
            </b-button>
            <!-- <b-button
              class="mx-1 btn-block"
              variant="outline-primary"
              data-action-type="pending"
              :disabled="selectedItem.lines.length <= 0 || isConsumed || (!selectedItem.sourceTransactionId && selectedItem.isPosted) || selectedItem.isComplete"
              @click="togglePendingItem"
            >
              <feather-icon
                icon="PauseIcon"
                class="mr-50"
              />
              {{ $t('saveAsDraft') }}
            </b-button>
            <b-button
              class="mx-1 btn-block"
              variant="relief-primary"
              data-action-type="retrive"
              :disabled="selectedItem.isPosted || pendingItems.length === 0"
              @click="(v) => {this.$refs['invoices-modal'].show();}"
            >
              <feather-icon
                icon="PlayIcon"
                class="mr-50"
              />
              {{ $t('retrive') }}
            </b-button> -->
            <b-button
              v-if="!selectedItem.sourceTransactionId"
              class="mx-1 btn-block"
              data-action-type="new"
              variant="outline-primary"
              @click="initObj()"
            >
              <feather-icon
                icon="RotateCwIcon"
                class="mr-50"
              />
              {{ $t('addNew') }}
            </b-button>
          </b-row>
        </b-card>
      </b-col>
      <!-- buttons -->
      <!-- <b-col md="3" class="d-flex flex-column">
        </b-col> -->
    </b-row>
    <b-modal
      ref="upload-modal"
      no-close-on-backdrop
      cancel-variant="outline-secondary"
      centered
      size="lg"
      hide-footer
      :title="$t('attachFile')"
    >
      <g-form>
        <b-row>
          <b-col cols="12">
            <div>
              <input
                type="file"
                class="excel-upload-input"
                accept=".pdf, .jpg, .jpeg, .bmp, .png, .doc, .docx, .zip"
                @change="handleFileUpload( $event )"
              >
              <b-alert
                v-if="file"
                show
                fade
                class="mt-2 text-center"
                variant="success"
              >
                <div class="alert-body">
                  <span>{{ $t('importSuccsefly') }} {{ file.name }}</span>
                </div>
              </b-alert>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <hr>
          </b-col>
          <b-col
            cols="12"
            class="d-flex justify-content-end"
          >
            <b-button
              class="mx-1"
              variant="primary"
              @click="closeModal"
            >
              {{ $t('save') }}
            </b-button>
            <b-button
              class="mx-1"
              variant="danger"
              data-action-type="delete"
              :disabled="!file"
              @click="() => {
                removeAttachment();
              }"
            >
              {{ $t('cancel') }}
            </b-button>
          </b-col>
        </b-row>
      </g-form>
    </b-modal>
    <b-modal
      ref="cost-centers-modal"
      no-close-on-backdrop
      cancel-variant="outline-secondary"
      centered
      size="xl"
      hide-footer
      :title="$t('attachFile')"
    >
      <b-row>
        <b-col md="12">
          <div class="divClass">
            <h3>{{ $t('shareToAccount') }} - {{ accountObject.arabicName }}</h3>
            <br>
            <h3 v-if="selectedItem.total !== 0">
              {{ $t('share') }} {{ $t('credit') }} {{ $t('withValue') }}
              {{
                fraction(currentBranch.taxPolicy === 'priceIncluded' ? selectedItem.netBeforeTaxes : selectedItem.total)
              }}
            </h3>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4">
          <g-field
            :value.sync="itemTransactionCostCenter.CostCenterId"
            :dir="isRight ? 'rtl' : 'ltr'"
            label-text="costCenter"
            field="select"
            name="costCenter"
            :options="costCenters"
            label="arabicName"
          />
        </b-col>
        <b-col md="3">
          <g-field
            :value.sync="itemTransactionCostCenter.value"
            :short-desc="true"
            rules="min_value:0"
            type="number"
            label-text="value"
          />
        </b-col>
        <b-col md="4">
          <g-field
            id="notes"
            :value.sync="itemTransactionCostCenter.notes"
            label-text="notes"
            name="notes"
          />
        </b-col>
        <b-col
          md="1"
          class="m-auto"
        >
          <b-button
            data-action-type
            class="mx-1"
            variant="primary"
            :disabled="!itemTransactionCostCenter.CostCenterId || !itemTransactionCostCenter.value || itemTransactionCostCenter.value < 0"
            @click="addCostcenter(itemTransactionCostCenter)"
          >
            <feather-icon icon="PlusCircleIcon" />
          </b-button>
        </b-col>
      </b-row>
      <b-row class="p-0">
        <b-col>
          <b-table
            :items="selectedItem.costCenters"
            :fields="costcenterTableColumns"
            primary-key="id"
            show-empty
            striped
            hover
            foot-clone
            sticky-column
            fixed
            class="position-relative"
            :empty-text="$t('noMatchingRecordsFound')"
          >
            <!-- Column: Name -->
            <template #cell(arabicName)="data">
              <span>
                {{ data.item.arabicName }}
              </span>
            </template>
            <template #head(actions)>
              <span />
            </template>
            <template #cell(actions)="data">
              <div class="text-nowrap">
                <feather-icon
                  :id="`invoice-row-${data.item}-delete-icon`"
                  icon="TrashIcon"
                  stroke="red"
                  class="mx-1 clickable danger"
                  @click="
                    (v) => {
                      removeCostCenter(data.item);
                    }
                  "
                />
                <b-tooltip
                  :title="$t('delete')"
                  placement="bottom"
                  :target="`invoice-row-${data.item.id}-delete-icon`"
                />
              </div>
            </template>
            <template #foot()>
              <span />
            </template>
            <template #foot(value)>
              <span> {{ fraction(totalCostcenters) }} </span>
            </template>
            <template #foot(notes)>
              <span> {{ $t('remining') }}  {{ fraction(reminderedCostcenters) }}</span>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="d-flex justify-content-end py-1 pb-2"
        >
          <b-button
            class="mx-1"
            variant="secondary"
            @click="closeCostCentersModal"
          >
            {{ $t('Close') }}
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <hr>
        </b-col>
      </b-row>
    </b-modal>
    <!-- expenses model -->
    <b-modal
      ref="cost-expenses-modal"
      no-close-on-backdrop
      cancel-variant="outline-secondary"
      centered
      size="xl"
      hide-footer
      :title="$t('enablePurchaseCosts')"
    >
      <b-row>
        <b-col cols="12">
          <expenses
            :items.sync="selectedItem.expenses"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="d-flex justify-content-end py-1 pb-2"
        >
          <b-button
            class="mx-1"
            variant="secondary"
            @click="closeExpensesModal"
          >
            {{ $t('Close') }}
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

  <script>
  import saveAs from 'file-saver';
  import invoiceMixin from '@/mixin/purchaseMixin';
  import reportMixin from '@/mixin/reportMixin';
  import GTable from '@/pages/Shared/Table.vue';
  import items from './components/items.vue';
  import itemsForCars from './components/itemsForCar.vue';
  import expenses from './components/expenses.vue';

  export default {
    components: {
      items,
      itemsForCars,
      expenses,
      GTable
    },
    mixins: [
      invoiceMixin, reportMixin,
    ],
    props: ['id'],
    data() {
      return {
        bankName: 0,
        banks: [],
        itemTransactionCostCenter: {},
        accountObject: {
          arabicName: null,
          branchId: null,
          code: null,
          currentCredit: null,
          currentDebit: null,
          englishName: null,
          finalAccountType: null,
          groupId: null,
          id: null,
          isCashAccount: null,
          isDistributable: null,
          level: null,
          nature: null,
          notes: null,
          parentId: null
        },
        costCenters: [],
        disableEdit: false,
        transactionType: 'purch',
        payemntType: 'Safes',
        paymentMethod: {
          value: null,
          cashPaid: 0,
          id: null,
          arabicName: '',
          englishName: '',
        },
        safeTransactions: {},
        customer: {},
        payment: {
          transactionId: 0,
          lineSerial: 0,
          value: 0,
          paymentMethod: 'safe',
          safeId: 0,
          otherPaymentMethodId: 0,
          referenceNumber: 'string',
        },
        fromTransactionType: '',
        items: [],
        errors: [],
        pendingItem: {},
        pendingItems: [],
        isCalculatingDiscount: false,
        file: '',
        suppliers: [],
        isConsumed: false,
        checkPaymentPurchOrder: false,
        currentPageList: 1,
        cachedRows: 25,
        cachedPerPage: 1,
        invoicesList: [],
        selectedItem: {
          costCenters: [],
          expenses: []
        },
        helper: {
          preventSave: false
        }
        // isSerialRepeated: false
      };
    },
    computed: {
      currentId() {
        return this.id === this.selectedItem.id
          ? this.id
          : this.selectedItem.id;
      },
      isSerialRepeated() {
      const lines = this.selectedItem.lines || [];
      const serialSet = new Set();
      // eslint-disable-next-line no-restricted-syntax
      for (const line of lines) {
        if (line.serial && serialSet.has(line.serial)) {
          return true;
        }
          serialSet.add(line.serial);
      }
      return false;
    },
      canAddNewPayment() {
          return (this.remaindered && Number(this.paymentMethod.value) > 0 && Number(this.paymentMethod.value) <= this.remaindered)
            && ((this.payemntType === 'Safes' && this.safeTransactions) || this.paymentMethod.otherPaymentMethodId);
      },
      isCash() {
        return this.selectedItem.paymentType === 'cash';
      },
      total() {
        return this.selectedItem.lines.reduce((sum, item) => {
          sum += item.net;
          return sum;
        }, 0);
      },
      paid() {
        return this.selectedItem.payments.reduce((sum, item) => {
          sum += parseFloat(item.value);
          return sum;
        }, 0);
      },
      remaindered() {
        return this.fraction(this.selectedItem.net - this.paid) || 0;
      },
      optionsRadio() {
        return [
          { text: this.$t('safe'), value: 'Safes' },
          { text: this.$t('other'), value: 'PaymentMethods' },
        ];
      },
       tableColumnsInvoice() {
        return [
          { key: 'transactionDate', label: this.$t('transactionDate'), sortable: true },
          {
            key: 'paymentType',
            label: this.$t('paymentType'),
            sortable: true,
            isLocale: true,
          },
          {
            key: 'netBeforeTaxes',
            label: this.$t('netBeforeTax'),
            sortable: true,
          },
          {
            key: 'net',
            label: this.$t('net'),
            sortable: true,
          },
          { key: 'actions' },
        ];
      },
      costcenterTableColumns() {
        return [
          { key: 'costCenterCode', label: this.$t('code'), sortable: false },
          {
            key: this.isRight ? 'costCenterArabicName' : 'costCenterEnglishName',
            label: this.$t('name'),
            sortable: false,
          },
          {
            key: 'value',
            label: this.$t('value'),
            sortable: false,
            type: 'number',
            footer: () => this.fraction(this.totalVal),
          },
          { key: 'notes', label: this.$t('notes'), sortable: false },
          { key: 'actions' },
        ];
      },
      totalCostcenters() {
        return this.selectedItem.costCenters.reduce((sum, item) => {
          sum += parseFloat(item.value);
          return sum;
        }, 0);
      },
      totalExpenses() {
        return this.selectedItem.expenses.reduce((sum, item) => {
          sum += item.isTaxApplied === false ? parseFloat(item.value) : parseFloat(item.netValue);
          return sum;
        }, 0);
      },
      reminderedCostcenters() {
        return this.fraction(this.currentBranch.taxPolicy === 'priceIncluded' ? this.selectedItem.netBeforeTaxes : this.selectedItem.total) - parseFloat(this.totalCostcenters) || 0;
      },
    },
    watch: {
      'selectedItem.paymentType'(newVal, oldVal) {
        if (this.disableEdit === false && newVal !== 'cash' && this.selectedItem.payments.length > 0) {
          this.selectedItem.paymentType = oldVal;
          this.confirmAction({
            text: this.$t('deletePaymentConfirmation'),
          }, () => {
            this.selectedItem.payments = [];
            this.selectedItem.paymentType = newVal;
          });
        }
      },
      'selectedItem.lines'(newVal, oldVal) {
        if (oldVal && oldVal.length > 0 && !this.currentId) this.selectedItem.payments = [];
      },
      remaindered(newVal) {
        this.paymentMethod.value = this.fraction(newVal);
        this.paymentMethod.cashPaid = this.fraction(newVal);
      },
      total(newval) {
        this.selectedItem.total = newval;
        this.computeTotals();
        if (this.selectedItem.payments.length && this.selectedItem.lines.length < 1) {
          this.selectedItem.payments = [];
        }
      },
      'selectedItem.total'(newVal, oldVal) {
        if (newVal !== oldVal) {
          if (this.customer.discount) {
            this.computeDiscount({ Percentage: this.selectedItem.discountPercentage });
          } else {
            this.computeDiscount({ value: this.selectedItem.discountValue });
          }
        }
      },
      'selectedItem.totalTaxes'(newVal) {
        if (newVal) {
         // const obj = this.suppliers.find(x => x.id === this.selectedItem.dealerId);
        // if (obj && !obj.isTaxable) {
        //   this.selectedItem.totalTaxes = 0;
        //   this.selectedItem.netBeforeTaxes = this.selectedItem.total - this.selectedItem.discountValue;
        //   this.selectedItem.net = this.selectedItem.netBeforeTaxes;
        // }
        }
      },
      'selectedItem.dealerId'(newVal) {
        if (newVal) {
        const obj = this.suppliers.find(x => x.id === this.selectedItem.dealerId);
        if (obj && !obj.isTaxable) {
          this.selectedItem.totalTaxes = 0;
          this.selectedItem.totalTaxes = this.selectedItem.netBeforeTaxes * (15 / 100);
          this.selectedItem.netBeforeTaxes = this.selectedItem.total - this.selectedItem.discountValue;
          this.selectedItem.net = this.selectedItem.netBeforeTaxes + this.selectedItem.totalTaxes;
        }
      }
    }
    },
    mounted() {
      this.getBanks();
      this.getSuppliers();
      // this.getPurchAccount();
      this.getCostCenters();
      this.updateViewModel();
      this.sourceId = this.selectedItem.sourceTransactionId || this.$route.query.sourceTransactionId;
      const id = this.id || this.selectedItem.sourceTransactionId;
      if (this.selectedItem.sourceTransactionId) {
        this.disableEdit = true;
      }
      // this.selectedItem.transactionType = this.transactionType;
      if (id > 0) {
        let transactionType = this.$route.query.sourceTransactionType;
        if (this.id) transactionType = this.transactionType;
        this.invoicesList = JSON.parse(sessionStorage.getItem('cachedPurchInvoices'));
        this.cachedRows = this.invoicesList?.length > 0 ? this.invoicesList.length : 1;
        this.get({ url: `ItemTransactions/${transactionType}/${id}` }).then((data) => {
          this.selectedItem = data;
          const indx = this.invoicesList.map(function (x) { return x.id; }).indexOf(data.id);
          this.currentPageList = indx + 1
          this.selectedItem.totalTaxes = data.totalTaxes;
          this.selectedItem.net = data.net;
          this.selectedItem.netBeforeTaxes = data.netBeforeTaxes;
          if (transactionType === 'purchOrder' && data.paymentType === 'cash' && data.paid > 0) {
              this.selectedItem.payments = [];
              this.selectedItem.paymentType = 'credit';
              this.checkPaymentPurchOrder = true;
            } else if (transactionType === 'purchOrder' && data.paymentType === 'credit') {
              this.checkPaymentPurchOrder = false;
            } else if (transactionType === 'purchOrder' && data.paymentType === 'cash' && data.paid === 0) {
              this.checkPaymentPurchOrder = false;
            }
          data.lines.forEach((item) => {
            item.transactionId = this.selectedItem.id;
            item.units = [
              {
                unitId: item.unitId,
                unitArabicName: item.unitArabicName,
                unitEnglishName: item.unitEnglishName,
                price: item.salesPrice,
                minSalesPrice: item.minSalesPrice,
                maxSalesPrice: item.maxSalesPrice,
                barcode: item.barcode,
                isMain: true,
              },
            ];
              item.barCode = item.barcode
          })
          this.isConsumed = data.lines.some((i) => i.consumedQuantity === 0);
          this.computeTotalTaxes(data.dealerId);
          if (Number(this.sourceId) > 0) this.prepareItemfromSource(transactionType);
        });
      }
    },
    methods: {
      checkDistribute(type) {
        if (this.accountObject.isDistributable && this.selectedItem.costCenters.length === 0) {
            this.confirmAction(
              {
                text: this.$t('saveWithoutDistribute'),
              },
              () => {
                this.save(type);
              }
            );
          } else {
            this.save(type);
          }
      },
      openExpensesModal() {
        this.$refs['cost-expenses-modal'].show();
      },
      closeExpensesModal() {
          this.$refs['cost-expenses-modal'].hide();
      },
      getItemChange(i) {
        this.updateViewModel();
        const dataItem = this.invoicesList[i - 1];
        this.get({ url: `ItemTransactions/${this.transactionType}/${dataItem.id}` }).then(
          (data) => {
            this.selectedItem = data;
            data.lines.forEach((item) => {
              item.originalQuantity = item.quantity;
              item.consumedQuantity = item.consumedQuantity === null ? 0 : item.consumedQuantity;
              item.units = [
              {
                unitId: item.unitId,
                unitArabicName: item.unitArabicName,
                unitEnglishName: item.unitEnglishName,
                price: item.salesPrice,
                minSalesPrice: item.minSalesPrice,
                maxSalesPrice: item.maxSalesPrice,
                barcode: item.barcode,
                isMain: true,
              },
              ]
              item.barCode = item.barcode
            });
            // if (this.sourceId > 0) this.prepareItemfromSource(transactionType);
          }
        );
        this.selectedItem.transactionTime = this.getTime(this.invoicesList[i - 1].transactionTime);
      },
      removeCostCenter(item) {
         const indx = this.selectedItem.costCenters.indexOf(item);
        this.selectedItem.costCenters.splice(indx, 1);
        // const costCenterItem = this.costCenters.find((val) => val.id === item.CostCenterId);
      },
      addCostcenter(item) {
        const costCenterItem = this.costCenters.find((val) => val.id === item.CostCenterId);
        if ((parseFloat(this.totalCostcenters) + parseFloat(item.value)) > this.fraction(this.currentBranch.taxPolicy === 'priceIncluded' ? this.selectedItem.netBeforeTaxes : this.selectedItem.total)) {
          const errorVal = parseFloat(this.totalCostcenters) + parseFloat(item.value) - this.fraction(this.currentBranch.taxPolicy === 'priceIncluded' ? this.selectedItem.netBeforeTaxes : this.selectedItem.total);
          this.doneAlert({
              text: `${this.$t('creditLessThanValueEnteredWith')} ${errorVal}`,
              type: 'error',
            });
          return;
        }
        this.selectedItem.costCenters.push({
              costCenterArabicName: costCenterItem.arabicName,
              costCenterEnglishName: costCenterItem.englishName,
              costCenterId: costCenterItem.id,
              costCenterCode: costCenterItem.code,
              TransactionId: this.id,
              lineSerial: this.selectedItem.costCenters.length + 1,
              ...item,
            });
        this.itemTransactionCostCenter.CostCenterId = null;
        this.itemTransactionCostCenter.value = null;
        this.itemTransactionCostCenter.notes = null;
      },
      openCostCentersModel() {
        this.$refs['cost-centers-modal'].show();
      },
      closeCostCentersModal() {
        if (this.reminderedCostcenters === 0) {
          this.$refs['cost-centers-modal'].hide();
        } else {
          this.doneAlert({ text: this.$t('youShouldDistributeAllAmount'), type: 'error' });
        }
      },
      getCostCenters() {
        this.get({ url: 'CostCenters?type=sub' }).then((data) => {
          this.costCenters = data;
        });
      },
      getPurchAccount() {
        if (this.currentBranch) {
          if (this.currentBranch.accounts.purchAccountId || this.currentBranch.accounts.storageAccountId) {
            var accId = this.currentBranch.stocktakingMethod === 'continuous' ? this.currentBranch.accounts.storageAccountId : this.currentBranch.accounts.purchAccountId;
            this.get({ url: `Accounts/${accId}` }).then((data) => {
              this.accountObject = data;
            });
          }
        }
      },
      getSuppliers() {
        this.get({ url: 'Suppliers/summary?dealerType=payments' }).then((data) => {
            this.suppliers = data;
          });
      },
      closeModal() {
        this.$refs['upload-modal'].hide();
      },
      openModal() {
        this.$refs['upload-modal'].show();
      },
      removeAttachment() {
        this.selectedItem.attachmentFileExtension = '';
        this.selectedItem.attachmentBase64Content = '';
        this.selectedItem.attachmentFileName = '';
        this.file = '';
        this.closeModal();
      },
      toBase64(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        });
      },
      handleFileUpload(event) {
        this.file = event.target.files[0];
        const fileSize = this.file.size / 1024 / 1024;
        this.selectedItem.attachmentFileExtension = this.file.name.split('.').pop();
          this.toBase64(this.file).then((file1) => {
            this.selectedItem.attachmentBase64Content = file1.split(',').pop();
          });
          this.selectedItem.attachmentFileName = URL.createObjectURL(this.file);
        if (!this.isValidType(this.file)) {
          this.file = '';
          this.selectedItem.attachmentFileExtension = '';
          this.selectedItem.attachmentBase64Content = '';
          this.selectedItem.attachmentFileName = '';
          this.doneAlert({ text: this.$t('Only supports upload .pdf, .jpg, .jpeg, .bmp, .png, .doc, .docx, .zip suffix files'), type: 'error' });
          this.file = '';
          return false
        }
        if (this.isValidType(this.file) && fileSize > 5) {
          this.file = '';
          this.selectedItem.attachmentFileExtension = '';
          this.selectedItem.attachmentBase64Content = '';
          this.selectedItem.attachmentFileName = '';
          this.doneAlert({ text: this.$t('fileSizeExceedsFiveMiB'), type: 'error' });
          this.file = '';
          return false
        }
      },
      isValidType(file) {
        return /\.(pdf|jpg|jpeg|bmp|png|doc|docx|zip)$/.test(file.name)
      },
      downloadItem(item) {
      const path = item.attachmentUrl.split('.')[1];
      saveAs(`${this.baseUrl}${item.attachmentUrl}`, `attached-file.${path}`);
    },
      checkForReferenceNumber(value) {
        const id = this.currentId ? this.currentId : 0;
        this.create({ url: `ItemTransactions/validate/ref-number/${id}?refNumber=${value}` })
        .then(() => {
        }).catch(() => {
         this.$swal({
          title: this.$t('Are you sure?'),
          text: this.$t('duplicatedREfNum, Do you want to continue?'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: this.$t('Yes!'),
          cancelButtonText: this.$t('Cancel'),
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.dismiss === 'cancel') {
            this.selectedItem.referenceNumber = '';
          }
        });
        });
      },
      addExtrnalItem() {
        var myWindow = window.open('/#/items/new', '', `width=${window.screen.availWidth}height=${window.screen.availHeight}`);
          var popupTick = setInterval(function () {
            if (myWindow.closed) {
              clearInterval(popupTick);
                // this.refreshTable()
            }
          }, 500);
      },
      addExtrnalSupplier() {
        var myWindow = window.open('/#/suppliers', '', `width=${window.screen.availWidth}height=${window.screen.availHeight}`);
          var popupTick = setInterval(function () {
            if (myWindow.closed) {
              clearInterval(popupTick);
                window.location.reload();
            }
          }, 500);
      },
      limit(event, limit) {
        if (this.selectedItem.notes.length >= limit) {
            event.preventDefault();
        }
      },
      prepareItemfromSource(sourceTransactionType) {
        this.fromTransactionType = sourceTransactionType;
        this.selectedItem.id = 0;
        if (this.sourceId) {
          this.selectedItem.isPosted = false;
          this.selectedItem.postedVoucherId = null;
          this.selectedItem.voucherCode = null;
        }
        this.selectedItem.transactionType = this.transactionType;
        this.selectedItem.uuid = '00000000-0000-0000-0000-000000000000';
        this.selectedItem.lines = this.selectedItem.lines.map((item) => {
           return {
            ...item,
            consumedQuantity: item.consumedQuantity === null ? 0 : item.consumedQuantity,
            originalQuantity: item.quantity,
            quantity: item.quantity - (item.consumedQuantity || 0),
          };
        });
        this.selectedItem.transactionTime = this.time;
        this.selectedItem.transactionDate = this.today;
        this.selectedItem.code = null;
        // if (sourceTransactionType === 'salesOrder') {
        //   this.selectedItem.payments = [];
        //   this.selectedItem.paymentType = 'credit';
        // }
        this.selectedItem.sourceTransactionId = this.sourceId;
      },
      togglePendingItem() {
        if (this.selectedItem.lines && this.selectedItem.lines.length > 0) {
          const pendingItem = JSON.parse(JSON.stringify(this.selectedItem));
          this.pendingItems.push(pendingItem);
          this.doneAlert({
              title: this.$t('pendingSucess'),
              type: 'success',
            });
          this.getSelecteItemObj();
        } else {
          this.doneAlert({ text: this.$t('selectItems'), type: 'warning' });
        }
      },
      retriveInvoice(item) {
        this.pendingItems = this.pendingItems.filter((v) => v !== item);
        this.selectedItem = item;
        this.$refs['invoices-modal'].hide();
      },
      changeCustomer(v) {
        if (!this.currentId) {
          this.selectedItem.agentId = v.agentId || null;
          this.selectedItem.onTheFlyDealerName = '';
        }
        if (!this.isLineBaseDiscount) {
          this.selectedItem.discountPercentage = v.applyDiscount && !this.isLineBaseDiscount ? v.discount : 0;
        }
        this.customer = v;
        this.selectedItem.discountPercentage = this.customer.discount;
        this.computeDiscount({ Percentage: this.selectedItem.discountPercentage })
        // this.computeDiscount({});
      },
      computeTotals() {
        // get discounted
        this.computeTotalDiscount();

        this.selectedItem.netBeforeTaxes = parseFloat(this.selectedItem.total) - parseFloat(this.selectedItem.discountValue) || 0;
        this.selectedItem.totalTaxes = 0;
        this.selectedItem.net = this.selectedItem.netBeforeTaxes;
        // compute tax values in mixin
        this.computeTotalTaxes(this.selectedItem.dealerId);

        this.selectedItem.net = this.fraction(this.selectedItem.net);
        this.selectedItem.netBeforeTaxes = this.fraction(this.selectedItem.netBeforeTaxes);
        this.selectedItem.totalTaxes = Number(this.fraction(this.selectedItem.totalTaxes));
        if (this.currentBranch.taxPolicy === 'priceIncluded') {
          this.selectedItem.netBeforeTaxes = this.selectedItem.net / (1 + (this.company.taxPercentage / 100));
          this.selectedItem.netBeforeTaxes = this.fraction(Number(this.selectedItem.netBeforeTaxes));
          this.selectedItem.totalTaxes = Number(this.selectedItem.net) - Number(this.selectedItem.netBeforeTaxes);
        }
      },
      computeDiscount({ value, Percentage }) {
        if (!this.total) return;
        if (this.isCalculatingDiscount) return;
          this.isCalculatingDiscount = true;
        value = value || 0;
        Percentage = Percentage || 0;
       if (value) {
          this.selectedItem.discountPercentage = this.fraction((value / parseFloat(this.total)) * 100);
        } else {
          this.selectedItem.discountValue = this.fraction((parseFloat(this.total) * Percentage) / 100);
        }
        // compute totals after apply discount
        this.computeTotals();
        this.isCalculatingDiscount = false;
      },
      addNewPaymentMethod() {
        if (this.paymentMethod.value) {
          this.preparePaymentModel();

          if (this.selectedItem.payments.some((p) => (p.safeId && p.safeId === this.paymentMethod.safeId) || (p.otherPaymentMethodId && p.otherPaymentMethodId === this.paymentMethod.otherPaymentMethodId))) {
            this.doneAlert({ text: this.$t('existingPayment'), type: 'error' });
          } else {
            this.paymentMethod.cashPaid = this.payemntType !== 'Safes' ? 0 : this.fraction(this.paymentMethod.cashPaid);
            if (this.paymentMethod.cashPaid < this.paymentMethod.value && this.payemntType === 'Safes') {
              this.paymentMethod.value = this.fraction(this.paymentMethod.cashPaid)
            }
            this.selectedItem.payments.push({ ...this.paymentMethod });
          }

          this.paymentMethod = {};
        }
      },
      beforeSaveCheckLines() {
        this.selectedItem.lines.forEach(item => {
          if (!item.serial || !item.carStatus || !item.carColorId) {
            this.notValidLine = true;
          } else {
            this.notValidLine = false;
          }
        });
        if (this.notValidLine) {
          // alert here
          this.doneAlert({ text: this.$t('notAllowedToSaveWithoutSerialOrColorOrCondition'), type: 'error' });
          this.isRequested = false;
          return false;
        }
        return true;
      },
      save(type) {
        if (this.selectedItem.lines.length <= 0) {
          this.doneAlert({ text: this.$t('selectItems'), type: 'error' });
          return;
        }
        if (!this.beforeSaveValidation()) return;
        if (this.currentBranch.activityType === 'carShowroom') {
          this.selectedItem.lines.forEach(line => {
          // Loop through attachmentDetails within the current line
          line.attachmentDetails.forEach(attachment => {
            if (attachment.attachmentUrl.startsWith(this.baseUrl)) {
              // Remove the base URL from the attachmentUrl property
              attachment.attachmentUrl = attachment.attachmentUrl.replace(this.baseUrl, '');
            }
          });
        });
          if (!this.beforeSaveCheckLines()) return;
        }
        if (!this.beforeSaveValidationDealer()) return;
        if (this.selectedItem.costCenters.length > 0 && this.reminderedCostcenters !== 0) {
          this.doneAlert({ text: this.$t('youShouldDistributeAllAmount'), type: 'error' });
          return;
        }
        if (this.errors.length > 0) {
          this.doneAlert(this.errors[0]);
          return;
        }
        if (this.selectedItem.lines.find(a => a.price <= 0) && !this.currentBranch.allowZeroItemPrice) {
          this.doneAlert({
            text: this.$t('youAreNotAllowedToAddSalesPriceEqualZero'),
            type: 'error'
            });
          return;
        }
        if (this.selectedItem.payments.length <= 0) {
          this.selectedItem.paymentType = 'credit';
        }
        // if paid amount more than needed
        if (this.remaindered < 0) {
          this.doneAlert({
            type: 'error',
            text: this.$t('paidMoreThanRequired'),
          });
          return;
        }
        if (!this.beforeSaveInvTypeValidation()) return;
        const url = `ItemTransactions/${this.transactionType}`;
        this.helper.preventSave = true;
        (this.currentId > 0 ? this.update({ url, id: this.currentId, data: this.getModel() }).then(() => {
          if (type === 'saveAndPrint') this.print(this.currentId);
        })
          : this.create({ url, data: this.getModel() }))
          .then((dataId) => {
            // if edit or from another source go to new after save
            if (this.currentId > 0 || this.selectedItem.sourceTransactionId > 0) this.$router.push({ name: 'purchInvoice' });
            this.doneAlert({
              title: this.$t('savedSuccessfully'),
              type: 'success',
            });
            if (type === 'saveAndPrint') {
              this.print(dataId);
              this.getSelecteItemObj();
            }
            this.helper.preventSave = false;
            if (this.selectedItem.id > 0) this.$router.push({ name: 'purchInvoice-list' });
            if (this.selectedItem.sourceTransactionId > 0) {
              this.$router.push({ name: 'purchInvoice-list' });
            } else {
                 this.getSelecteItemObj();
            }
             return this.getSelecteItemObj();
          });
      },
      initObj() {
        this.getSelecteItemObj();
        this.selectedItem.transactionType = this.transactionType;
        this.currentId = 0;
      },
      getBanks() {
        this.get({ url: 'Banks' }).then((data) => {
          this.banks = data;
          this.getSelecteItemObj();
        });
      },
       print(id) {
        const printedItem = {
          id: id,
          branchId: this.currentBranch.id
        }
         this.printReport('Purchase-ar', printedItem);
      },
    },
  };
  </script>
  <style>
  .display{
    display: none;
  }
  .sticky-content-top2{
      position: sticky;
      top: calc(85px + 300px);
  }
  .sticky-bottom {
      padding: 0.8rem 1rem;
      background: transparent;
   }
  </style>
