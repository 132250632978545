var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"my-2"},[_c('b-col',{staticClass:"pb-2",attrs:{"cols":"12"}},[_c('b-form-radio-group',{attrs:{"button-variant":"outline-primary","options":_vm.optionsRadio,"buttons":"","size":"sm","name":"radios-btn-default"},model:{value:(_vm.searchType),callback:function ($$v) {_vm.searchType=$$v},expression:"searchType"}})],1),_c('b-row',{attrs:{"align-h":"between"}},[(_vm.searchType === 'name')?_c('b-col',{attrs:{"md":"6"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-start"},[_c('vue-autosuggest',{attrs:{"suggestions":_vm.filteredOptions,"input-props":_vm.inputProps,"get-suggestion-value":_vm.getSuggestionValue,"limit":10},on:{"selected":_vm.onSelected,"click":_vm.clickHandler,"input":_vm.onInputChange},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var suggestion = ref.suggestion;
return [_c('span',{staticClass:"my-suggestion-item"},[_vm._v(_vm._s(suggestion.item.itemArabicName))])]}}],null,false,1269340220),model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}})],1)]):_vm._e(),(_vm.searchType === 'barcode')?_c('b-col',{attrs:{"cols":"12","md":"5"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[_c('b-form-input',{staticClass:"d-inline-block mr-1",attrs:{"clearable":true,"placeholder":_vm.$t('search')},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.getItemWithBarcode.apply(null, arguments)},"dblclick":function () { return _vm.$refs.searchItemsModal.openModal(_vm.transactionType); }},model:{value:(_vm.filter.barcode),callback:function ($$v) {_vm.$set(_vm.filter, "barcode", $$v)},expression:"filter.barcode"}})],1)]):_vm._e(),_c('b-col',{staticClass:"d-flex justify-content-end",attrs:{"md":"2"}},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function (v) {
              _vm.$refs.searchItemsModal.openModal(_vm.transactionType);
            }}},[_vm._v(" "+_vm._s(_vm.$t("addNewItem"))+" ")])],1)],1)],1),_c('b-table',{ref:"g-table",staticClass:"mb-0",attrs:{"items":_vm.items,"fields":_vm.columns,"primary-key":"barcode","show-empty":"","hover":"","small":"","foot-clone":"","sticky-header":"100%","sticky-column":"","per-page":_vm.items.length,"current-page":_vm.currentPage,"sort-by":_vm.sortBy,"sort-desc":_vm.isSortDirDesc,"sort-direction":_vm.sortDirection,"empty-text":_vm.$t('noMatchingRecordsFound')},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.isSortDirDesc=$event},"update:sort-desc":function($event){_vm.isSortDirDesc=$event}},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(scope){return _vm._l((scope.fields),function(field){return _c('col',{key:field.key,style:({ width: '180px' })})})}},{key:"head(actions)",fn:function(){return [_c('span')]},proxy:true},{key:"cell(serial)",fn:function(ref){
            var item = ref.item;
return [_c('g-field',{staticClass:"mt-1",attrs:{"id":"serial","value":item.serial,"rules":"required","size":"sm","name":"serial"},on:{"update:value":function($event){return _vm.$set(item, "serial", $event)}}})]}},{key:"cell(carColorId)",fn:function(ref){
            var item = ref.item;
return [_c('g-field',{staticClass:"select-size-sm my-0 custom-dropdown",staticStyle:{"position":"relative !important"},attrs:{"field":"select","value":item.carColorId,"options":_vm.carColors,"tool-tip-error":true,"label":_vm.isRight ? 'arabicName' : 'englishName',"dir":_vm.isRight ? 'rtl' : 'ltr',"rules":"required"},on:{"update:value":function($event){return _vm.$set(item, "carColorId", $event)},"input":function (v) {
            _vm.computeTotal({ item: item });
          }}})]}},{key:"cell(carStatus)",fn:function(ref){
          var item = ref.item;
return [_c('g-field',{staticClass:"select-size-sm my-0 custom-dropdown",staticStyle:{"position":"relative !important"},attrs:{"field":"select","value":item.carStatus,"options":_vm.carConditions,"tool-tip-error":true,"label":_vm.isRight ? 'arabicName' : 'englishName',"dir":_vm.isRight ? 'rtl' : 'ltr',"rules":"required"},on:{"update:value":function($event){return _vm.$set(item, "carStatus", $event)},"input":function (v) {
            _vm.computeTotal({ item: item });
          }}})]}},{key:"cell(arabicName)",fn:function(ref){
          var item = ref.item;
return [_c('span',{staticStyle:{"min-width":"150px"}},[_vm._v(" "+_vm._s(item.arabicName))])]}},{key:"cell(discount)",fn:function(ref){
          var item = ref.item;
return [_c('b-row',[_c('b-col',{staticClass:"p-0 pr-1",attrs:{"cols":"6"}},[_c('g-field',{staticClass:"my-0",attrs:{"value":item.discountValue,"placeholder":"value","type":"number","size":"sm"},on:{"update:value":function($event){return _vm.$set(item, "discountValue", $event)},"input":function (v) {
                _vm.computeTotal({
                  item: item,
                  discountValue: item.discountValue,
                });
              }}})],1),_c('b-col',{staticClass:"p-0",attrs:{"cols":"6"}},[_c('g-field',{staticClass:"my-0",attrs:{"value":item.discountPercentage,"placeholder":"%","type":"number","size":"sm"},on:{"update:value":function($event){return _vm.$set(item, "discountPercentage", $event)},"input":function (v) {
                _vm.computeTotal({
                  item: item,
                  discountPercentage: item.discountPercentage,
                });
              }},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('b-input-group-text',[_vm._v(" % ")])]},proxy:true}],null,true)})],1)],1)]}},{key:"cell(quantity)",fn:function(ref){
              var item = ref.item;
return [_c('g-field',{staticClass:"my-0",style:({ width: '100px' }),attrs:{"value":item.quantity,"disabled":true,"type":"number","rules":("required|min_value:0|" + (_vm.hasSource
            ? 'less_or_equal:' +
              (item.originalQuantity - (item.consumedQuantity || 0))
            : '')),"tool-tip-error":false,"label-text":"quantity","hide-lable":true,"size":"sm"},on:{"update:value":function($event){return _vm.$set(item, "quantity", $event)},"input":function (v) {
            _vm.computeTotal({ item: item });
          }}})]}},{key:"cell(price)",fn:function(ref){
          var item = ref.item;
return [_c('g-field',{staticClass:"mb-0",style:({ width: '100px' }),attrs:{"value":item.price,"type":"number","rules":"required","short-desc":true,"size":"sm"},on:{"update:value":function($event){return _vm.$set(item, "price", $event)},"input":function (v) {
            _vm.computeTotal({ item: item });
          },"blur":function($event){return _vm.zeroPrice(item)},"change":function($event){return _vm.zeroPrice(item)}}})]}},{key:"cell(notes)",fn:function(ref){
          var item = ref.item;
return [_c('g-field',{staticClass:"my-0",attrs:{"value":item.notes,"short-desc":true,"size":"sm"},on:{"update:value":function($event){return _vm.$set(item, "notes", $event)}}})]}},{key:"cell(actions)",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"text-nowrap"},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(_vm.$t('delete')),expression:"$t('delete')",modifiers:{"hover":true,"top":true}}],attrs:{"variant":"flat-danger","size":"sm"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('feather-icon',{staticClass:"mx-0 clickable danger",attrs:{"id":("invoice-row-" + (item.id) + "-delete-icon"),"icon":"TrashIcon","stroke":"red"}})],1),(item.serial)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(_vm.$t('attachments')),expression:"$t('attachments')",modifiers:{"hover":true,"top":true}}],attrs:{"variant":"flat-primary","size":"sm"},on:{"click":function($event){return _vm.openModal(item)}}},[_c('feather-icon',{staticClass:"mx-0 clickable warning",attrs:{"id":("invoice-row-" + (item.id) + "-primary-icon"),"icon":"FileIcon"}})],1):_vm._e()],1)]}},{key:"foot()",fn:function(){return [_c('span')]},proxy:true},{key:"foot(quantity)",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.fraction(_vm.totalQuantity))+" ")])]},proxy:true},{key:"foot(total)",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.fraction(_vm.totalPrices))+" ")])]},proxy:true},{key:"foot(discount)",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.fraction(_vm.totalDiscountValue))+" ")])]},proxy:true},{key:"foot(net)",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.fraction(_vm.totalNetPrice))+" ")])]},proxy:true}])}),_c('b-modal',{ref:"attachments-modal",attrs:{"no-close-on-backdrop":"","cancel-variant":"outline-secondary","centered":"","size":"xl","hide-footer":"","title":_vm.$t('attachments')}},[_c('b-row',[_vm._l((_vm.selectedItemAttached),function(item,index){return _c('b-col',{key:index,staticClass:"text-center",attrs:{"md":"2"}},[_c('span',{staticClass:"removeImage text-right btn-block",on:{"click":function($event){return _vm.deleteImage(item)}}},[_vm._v("×")]),(
            item.filePath === 'png' ||
              item.filePath === 'jpg' ||
              item.filePath === 'peg' ||
              item.filePath === 'PNG' ||
              item.filePath === 'JPG' ||
              item.filePath === 'PEG' ||
              item.attachmentFileExtension === 'png' ||
              item.attachmentFileExtension === 'jpg' ||
              item.attachmentFileExtension === 'peg' ||
              item.attachmentFileExtension === 'PNG' ||
              item.attachmentFileExtension === 'JPG' ||
              item.attachmentFileExtension === 'PEG'
          )?_c('b-media',{staticClass:"d-block m-auto text-center",attrs:{"no-body":""}},[_c('b-link',[(item.attachmentUrl)?_c('b-img',{ref:"previewEl",refInFor:true,attrs:{"rounded":"","height":"150","width":"150","src":item.attachmentUrl},on:{"click":function($event){return _vm.showPreview(item.attachmentUrl)}}}):_vm._e()],1)],1):_c('div',[_c('feather-icon',{attrs:{"icon":"FileIcon","size":"150"}})],1),_c('b-row',{staticClass:"mt-75"},[_c('b-col',{staticClass:"text-center",attrs:{"md":"12"}},[(item.attachmentUrl.startsWith(_vm.baseUrl))?_c('b-button',{staticClass:"btn-sm btn btn-primary",attrs:{"variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.downloadItem(item)}}},[_c('feather-icon',{staticClass:"ml-25",attrs:{"icon":"DownloadIcon","size":"16"}})],1):_vm._e(),_c('input',{staticClass:"excel-upload-input mt-1",attrs:{"type":"file","accept":".pdf,.png,.jpg,.jpeg,.PDF,.PNG,.JPG,.JPEG"},on:{"change":function($event){return _vm.handleFileReplace( $event, item )}}})],1)],1)],1)}),(_vm.selectedItemAttached.length < 5)?_c('b-col',{attrs:{"md":"2"}},[_c('input',{staticStyle:{"width":"200px"},attrs:{"type":"file","accept":".pdf,.png,.jpg,.jpeg,.PDF,.PNG,.JPG,.JPEG","multiple":""},on:{"change":function($event){return _vm.handleFileUpload($event, _vm.clickedItem)}}})]):_vm._e()],2)],1),_c('b-modal',{ref:"upload-modal",attrs:{"no-close-on-backdrop":"","cancel-variant":"outline-secondary","centered":"","size":"xl","hide-footer":"","title":_vm.$t('attachments')}},[_c('b-row',[_vm._l((_vm.clickedItem.attachmentDetails),function(item,index){return _c('b-col',{key:index,staticClass:"text-center",attrs:{"md":"2"}},[_c('span',{staticClass:"removeImage text-right btn-block",on:{"click":function($event){return _vm.deleteImage(item)}}},[_vm._v("×")]),(
            item.filePath === 'png' ||
              item.filePath === 'jpg' ||
              item.filePath === 'peg' ||
              item.filePath === 'PNG' ||
              item.filePath === 'JPG' ||
              item.filePath === 'PEG' ||
              item.attachmentFileExtension === 'png' ||
              item.attachmentFileExtension === 'jpg' ||
              item.attachmentFileExtension === 'peg' ||
              item.attachmentFileExtension === 'PNG' ||
              item.attachmentFileExtension === 'JPG' ||
              item.attachmentFileExtension === 'PEG'
          )?_c('b-media',{staticClass:"d-block m-auto text-center",attrs:{"no-body":""}},[_c('b-link',[(item.attachmentUrl)?_c('b-img',{ref:"previewEl",refInFor:true,attrs:{"rounded":"","height":"150","width":"150","src":item.attachmentUrl},on:{"click":function($event){return _vm.showPreview(item.attachmentUrl)}}}):_vm._e()],1)],1):_c('div',[_c('feather-icon',{attrs:{"icon":"FileIcon","size":"150"}})],1)],1)}),(_vm.clickedItem.attachmentDetails && _vm.clickedItem.attachmentDetails.length < 5)?_c('b-col',{attrs:{"md":"2"}},[_c('input',{staticStyle:{"width":"200px"},attrs:{"type":"file","accept":".pdf,.png,.jpg,.jpeg,.PDF,.PNG,.JPG,.JPEG","multiple":""},on:{"change":function($event){return _vm.handleFileUpload($event, _vm.clickedItem)}}})]):_vm._e()],2)],1),(_vm.previewImage)?_c('div',{staticClass:"modalImage pt-5"},[_c('span',{staticClass:"closeImage",on:{"click":_vm.hidePreview}},[_vm._v("×")]),_c('img',{staticClass:"modalImage-content",attrs:{"src":_vm.previewImage}})]):_vm._e(),_c('search-items',{ref:"searchItemsModal",attrs:{"store-id":_vm.storeId,"search-type":"purchase"},on:{"addNewItems":_vm.addItems}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }